jQuery(document).ready(function()
{
	jQuery(":not([ng-repeat]) .tokeninput").each(function()
	{
		var ti = jQuery(this);
		var source 		= ti.attr("data-source");
		var allow_new 	= ti.attr("data-allow_new");
		var options 	= ti.attr("data-options");

		options = JSON.parse(options.replace(/'/g, '"'));
		options.onReady = function()
		{
			ti.addClass("ready");

			jQuery("#token-input-" + ti.attr("id")).keyup(function(e)
			{
				if(e.keyCode == 13)
				{
					e.preventDefault();

					if(allow_new === true || allow_new == 1)
					{
						var val = jQuery(this).val();

						if(val == "")
						{
							return;
						}
						ti.tokenInput("add", {id:val, name:val});
					}
				}
			});

			// Key Press needs to be disabled for enter key at all times.
			jQuery("#token-input-" + ti.attr("id")).keypress(function(e)
			{
				if(e.keyCode == 13)
				{
					e.preventDefault();
				}
			});
		};

		ti.tokenInput(source, options);
	});
});
